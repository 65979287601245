import React from "react"
import { shape } from "prop-types"
import styled from "@emotion/styled"
import Fade from "react-reveal/Fade"
import TeaserImage from "../atoms/teaser-image"
import Heading3 from "../atoms/heading-3"
import RichText from "./rich-text"

const propTypes = {
  fluid: shape().isRequired,
  data: shape().isRequired,
}

const ContactTitle = styled(Heading3)`
  font-size: 100%;
  @media ${({ theme }) => theme.breakpoint.mobile} {
    font-size: 100% !important;
  }
  @media ${({ theme }) => theme.breakpoint.desktop} {
    font-size: 85% !important;
  }
  margin-bottom: 1em;
  display: block;
`

const Contact = ({ fluid, data: contact }) => {
  return (
    <>
      <Fade force bottom distance="3em" delay={1000} duration={1500}>
        <div>
          {contact.image && (
            <TeaserImage itemProp="image" fluid={fluid} alt={contact.title} />
          )}
          <ContactTitle>{contact.title}</ContactTitle>
          {contact.body && <RichText type="small" data={contact.body} />}
        </div>
      </Fade>
    </>
  )
}

Contact.propTypes = propTypes

export default Contact
