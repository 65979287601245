import React from "react"
import { arrayOf, shape } from "prop-types"
import Fade from "react-reveal/Fade"
import PageHeader from "./molecules/page-header"
import ThreeColumnBlock from "./molecules/three-column-block"
import ContactList from "./molecules/contact-list"
import HeroImage from "./molecules/hero-image"

const propTypes = {
  blocks: arrayOf(shape()),
}

const defaultProps = {
  blocks: [],
}

const ContentBlocks = ({ blocks, delayOffset = 50 }) => {
  return (
    blocks &&
    blocks.map((block, index) => {
      const delay = index > 0 ? 300 * index : delayOffset
      // eslint-disable-next-line no-underscore-dangle
      switch (block.__typename) {
        case "ContentfulPageHeader":
          return (
            <Fade
              force
              key={block.id}
              bottom
              distance="3em"
              delay={delay}
              duration={1500}
            >
              <PageHeader data={block} />
            </Fade>
          )
        case "ContentfulThreeColumnBlock":
          if (
            block.columns[0].body === null &&
            block.columns[1].body === null &&
            block.columns[2].body === null
          ) {
            return null
          }
          return (
            <Fade
              force
              key={block.id}
              bottom
              distance="3em"
              delay={delay}
              duration={1500}
            >
              <ThreeColumnBlock data={block} />
            </Fade>
          )
        case "ContentfulContactList":
          return (
            <Fade
              force
              key={block.id}
              bottom
              distance="3em"
              delay={delay}
              duration={1500}
            >
              <ContactList data={block} />
            </Fade>
          )
        case "ContentfulHeroImage":
          return (
            <Fade
              force
              key={block.id}
              bottom
              distance="3em"
              delay={delay}
              duration={1500}
            >
              <HeroImage image={block.image} />
            </Fade>
          )
        default:
          return null
      }
    })
  )
}

ContentBlocks.propTypes = propTypes

ContentBlocks.defaultProps = defaultProps

export default ContentBlocks
