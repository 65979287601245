import React, { useContext } from "react"
import { shape } from "prop-types"
import styled from "@emotion/styled"
import { Link } from "gatsby-plugin-intl"
import ModalContext from "../molecules/modal-context"

const propTypes = {
  data: shape().isRequired,
}

/**
 * Changes to button styles must be done to both buttons
 */

const StyledLink = styled(Link)`
  display: inline-block;
  border: 1px solid ${({ theme }) => theme.currentColours.fg};
  color: ${({ theme }) => theme.currentColours.fg};
  border-radius: 500px;
  font-size: 90%;
  padding: 0.45em 1.2em 0.45em 1.3em;
  line-height: 1.681818em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: none;
  @media ${(props) => props.theme.breakpoint.desktop} {
    font-size: 70% !important;
  }
  margin-bottom: 1.5vw;
`
const StyledButton = styled.button`
  display: inline-block;
  background: none;
  width: auto;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.currentColours.fg};
  color: ${({ theme }) => theme.currentColours.fg};
  border-radius: 500px;
  font-size: 90%;
  padding: 0.45em 1.2em 0.45em 1.3em;
  line-height: 1.681818em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  -webkit-appearance: none;
  &:-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  @media ${(props) => props.theme.breakpoint.desktop} {
    font-size: 70% !important;
  }
  margin-bottom: 1.5vw;
`
const Button = ({ data: button }) => {
  const setModalOpen = useContext(ModalContext)
  const { title, slug } = button
  if (slug.includes("#contact")) {
    return (
      <StyledButton onClick={() => setModalOpen(slug)}>{title}</StyledButton>
    )
  }
  if (slug.includes("#maintenance")) {
    return (
      <StyledButton onClick={() => setModalOpen(slug)}>{title}</StyledButton>
    )
  }
  if (slug.includes("tel:") || slug.includes("mailto:")) {
    return (
      <StyledButton as="a" href={slug}>
        {title}
      </StyledButton>
    )
  }
  return title && slug ? <StyledLink to={`/${slug}`}>{title}</StyledLink> : null
}

Button.propTypes = propTypes

export default Button
