import React from "react"
import { shape } from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"

const propTypes = {
  image: shape(),
}

const defaultProps = {
  image: null,
}

const Image = styled(Img)`
  margin-bottom: 5vw;
`

const HeroImage = ({ image }) => {
  return (
    <StaticQuery
      query={graphql`
        query allContentfulHeroImage {
          allContentfulHeroImage(filter: { node_locale: { eq: "fi" } }) {
            edges {
              node {
                image {
                  contentful_id
                  fluid(maxWidth: 2048, maxHeight: 410) {
                    ...GatsbyContentfulFluid
                  }
                }
              }
            }
          }
        }
      `}
      render={imageData => {
        const images = []
        imageData.allContentfulHeroImage.edges.forEach(i => {
          if (i.node.image) {
            images[i.node.image.contentful_id] = i.node.image.fluid
          }
        })
        return (
          <>
            {image && (
              <Image
                itemProp="image"
                fluid={images[image.contentful_id]}
                alt={image.title}
              />
            )}
          </>
        )
      }}
    />
  )
}

HeroImage.propTypes = propTypes
HeroImage.defaultProps = defaultProps

export default HeroImage
