import React from "react"
import styled from "@emotion/styled"
import { shape, string } from "prop-types"

const propTypes = {
  children: shape().isRequired,
  className: string,
}

const StyledBlockWrapper = styled.div`
  margin-bottom: 5vw;
`

const BlockWrapper = ({ children, className }) => (
  <StyledBlockWrapper className={className}>{children}</StyledBlockWrapper>
)

const defaultProps = {
  className: "",
}

BlockWrapper.propTypes = propTypes
BlockWrapper.defaultProps = defaultProps

export default BlockWrapper
