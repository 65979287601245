import React from "react"
import { shape } from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { Flex, Box } from "rebass"
import theme from "../../config/theme"
import Teaser from "./teaser"
import BlockWrapper from "../atoms/block-wrapper"

const propTypes = {
  data: shape().isRequired,
}

const ThreeColumnBlock = ({ data }) => {
  const { columns } = data
  return (
    <StaticQuery
      query={graphql`
        query allContentfulThreeColumnBlock {
          allContentfulThreeColumnBlock(filter: { node_locale: { eq: "fi" } }) {
            edges {
              node {
                columns {
                  id
                  image {
                    contentful_id
                    fluid(maxWidth: 768, maxHeight: 443) {
                      ...GatsbyContentfulFluid
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={imageData => {
        const images = []
        imageData.allContentfulThreeColumnBlock.edges.forEach(i => {
          return (
            i.node.columns &&
            i.node.columns.forEach(k => {
              if (k.image) {
                images[k.image.contentful_id] = k.image.fluid
              }
            })
          )
        })
        return (
          <BlockWrapper>
            <Flex mx={theme.flexGutter} flexWrap="wrap">
              {columns.map(c => (
                <Box
                  width={[1, 1, 1 / 3]}
                  px={theme.boxGutter}
                  mb={theme.boxBottomMargin}
                  key={`${c.id}`}
                >
                  <Teaser images={images} data={c} />
                </Box>
              ))}
            </Flex>
          </BlockWrapper>
        )
      }}
    />
  )
}

ThreeColumnBlock.propTypes = propTypes

export default ThreeColumnBlock
