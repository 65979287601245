import React from "react"
import { arrayOf, shape } from "prop-types"
import styled from "@emotion/styled"
import Fade from "react-reveal/Fade"
import TeaserImage from "../atoms/teaser-image"
import Heading3 from "../atoms/heading-3"
import RichText from "./rich-text"
import Button from "../atoms/button"

const propTypes = {
  images: arrayOf(shape()),
  data: shape().isRequired,
}
const defaultProps = {
  images: [],
}

const TeaserTitle = styled(Heading3)`
  margin-bottom: 1em;
  display: block;
`
const ButtonWrapper = styled.div`
  margin-top: 2.5em;
  margin-bottom: 1em;
`

const Teaser = ({ images, data: teaser }) => {
  return (
    <>
      <Fade bottom distance="3em" duration={1000} delay={400}>
        <div id={`teaser_${teaser.id}`}>
          {teaser.image && (
            <TeaserImage
              fluid={images[teaser.image.contentful_id]}
              alt={teaser.title}
            />
          )}
          <TeaserTitle dangerouslySetInnerHTML={{ __html: teaser.title }} />
          {teaser.body && (
            <RichText type="small" data={teaser.body} itemProp="text" />
          )}
          {teaser.button && (
            <ButtonWrapper>
              <Button data={teaser.button} />
            </ButtonWrapper>
          )}
        </div>
      </Fade>
    </>
  )
}

Teaser.propTypes = propTypes

Teaser.defaultProps = defaultProps

export default Teaser
