import React from "react"
import { shape } from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { Flex, Box } from "rebass"
import theme from "../../config/theme"
import Contact from "./contact"
import BlockWrapper from "../atoms/block-wrapper"

const propTypes = {
  data: shape().isRequired,
}

const ContactList = ({ data }) => {
  const { columns } = data
  return (
    <StaticQuery
      query={graphql`
        query allContentfulContactList {
          allContentfulContactList(filter: { node_locale: { eq: "fi" } }) {
            edges {
              node {
                columns {
                  id
                  image {
                    contentful_id
                    fluid(maxWidth: 768, maxHeight: 524) {
                      ...GatsbyContentfulFluid
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={imageData => {
        const images = []
        imageData.allContentfulContactList.edges.forEach(i => {
          return (
            i.node.columns &&
            i.node.columns.forEach(k => {
              if (k.image) {
                images[k.image.contentful_id] = k.image.fluid
              }
            })
          )
        })
        return (
          <BlockWrapper>
            <Flex mx={theme.flexGutter} flexWrap="wrap">
              {columns.map(c => (
                <Box
                  width={[1, 1 / 2, 1 / 2, 1 / 4]}
                  px={theme.boxGutter}
                  mb={theme.boxBottomMargin}
                  key={`${c.id}`}
                >
                  <Contact fluid={images[c.image.contentful_id]} data={c} />
                </Box>
              ))}
            </Flex>
          </BlockWrapper>
        )
      }}
    />
  )
}

ContactList.propTypes = propTypes

export default ContactList
