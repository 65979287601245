import React from "react"
import { shape } from "prop-types"
import { Flex, Box } from "rebass"
import styled from "@emotion/styled"
import theme from "../../config/theme"
import RichText from "./rich-text"
import PageTitle from "../atoms/page-title"
import BlockWrapper from "../atoms/block-wrapper"

const propTypes = {
  data: shape().isRequired,
}

const StyledBlockWrapper = styled(BlockWrapper)`
  padding-top: 1em;
`

const PageHeader = ({ data }) => {
  const {
    title,
    childContentfulPageHeaderColumn2RichTextNode: column2,
    childContentfulPageHeaderColumn3RichTextNode: column3,
  } = data
  return (
    <StyledBlockWrapper>
      <Flex mx={theme.flexGutter} flexWrap="wrap" itemProp="description">
        <Box width={[1, 1, 1 / 3]} px={theme.boxGutter}>
          <PageTitle itemProp="name">{title}</PageTitle>
        </Box>
        {column2 && (
          <Box
            width={column2 && column3 ? [1, 1, 1 / 3] : [1, 1, 2 / 3]}
            px={theme.boxGutter}
          >
            <RichText data={column2} />
          </Box>
        )}
        {column3 && (
          <Box width={[1, 1, 1 / 3]} px={theme.boxGutter}>
            <RichText data={column3} />
          </Box>
        )}
      </Flex>
    </StyledBlockWrapper>
  )
}

PageHeader.propTypes = propTypes

export default PageHeader
